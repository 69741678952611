import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private confirmDialogService: ConfirmationService) {
  }

  confirm(options:any){
    return this.confirmDialogService.confirm(options);
  }
}
